import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import { Grid } from '@material-ui/core';
import SectionContainerLayout from '../../components/section-container-layout/section-container-layout';
export default function Press() {
  return (
    <Layout>
      <SEO lang='en' title='Press | Global Manufacturing & Industrialisation Summit' />
      <SectionContainerLayout title='PRESS' baseLink='/press' isDivider>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={3}>
            <CardImageTitleDescription
              image='press-registration'
              title='PRESS REGISTRATION'
              readMore='/press/registration'
            />
          </Grid> */}
          {/* <Grid item xs={12} md={3}>
            <CardImageTitleDescription image='press-event' title='PRESS EVENTS' readMore='/press/event' />
          </Grid> */}
          <Grid item xs={12} md={3}>
            <CardImageTitleDescription image='press-contact' title='PRESS CONTACTS' readMore='/press/contact' />
          </Grid>

          <Grid item xs={12} md={3}>
            <CardImageTitleDescription image='press-news' title='NEWS' readMore='/news' />
          </Grid>
          <Grid item xs={12} md={3}>
            <CardImageTitleDescription image='press-knowledge' title='KNOWLEDGE HUB' readMore='/knowledge-hub' />
          </Grid>
          <Grid item xs={12} md={3}>
            <CardImageTitleDescription image='press-gallery' title='GALLERY' readMore='/galleries' />
          </Grid>
          <Grid item xs={12} md={3}>
            {/* <CardImageTitleDescription image='press-partners' title='Global Network' readMore='/partners' /> */}
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <CardImageTitleDescription
              image='press-and-media-information-press'
              title='Press and Media Information'
              readMore='/press/media-information'
            />
          </Grid> */}
          {/* <Grid item xs={12} md={3}>
            <CardImageTitleDescription
              image='plan-your-trip-resize'
              title='PLAN YOUR TRIP'
              readMore='/press/plan-your-trip'
            />
          </Grid> */}
        </Grid>
      </SectionContainerLayout>
    </Layout>
  );
}
